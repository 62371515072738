export default {
    namespaced: true,
    state: () => ({
        duocQuetMa: true,
        dataQuetMa: null,
        dangQuetMa: 0
    }),
    getters: {
        duocQuetMa: state => state.duocQuetMa,
        dataQuetMa: state => state.dataQuetMa,
        dangQuetMa: state => state.dangQuetMa
    },
    mutations: {
        setDuocQuetMa(state, value) {
            state.duocQuetMa = value;
        },
        setDataQuetMa(state, value) {
            state.dataQuetMa = value;
        },
        setDangQuetMa(state) {
            state.dangQuetMa = state.dangQuetMa + 1;
            console.log(
                "🚀 ~ file: QrCode.js ~ line 22 ~ setDangQuetMa ~ state.dangQuetMa",
                state.dangQuetMa
            );
        }
    },
    actions: {
        quetMaQR({ commit }) {
            if (SonPhat) {
                commit("setDuocQuetMa", true);
                var u = new URL(location.href);
                u.search = "";
                u.pathname = "qr-overlay.html";
                SonPhat.openQrScannerV1(u + "");
            }
        },
        nhanMaQR({ state, commit }) {
            window.removeEventListener("sp-broadcast", () => {
                commit("setDuocQuetMa", true);
            });
            window.addEventListener("sp-broadcast", function(e) {
                try {
                    if (!state.duocQuetMa) {
                        return;
                    }
                    if (e instanceof window.MessageEvent) {
                        var msg = JSON.parse(e.data);
                        if (msg.action == "qr-callback") {
                            commit("setDuocQuetMa", false);
                            console.log(
                                "🚀 ~ file: tabs.vue ~ line 129 ~ data",
                                msg.data
                            );
                            commit("setDataQuetMa", msg.data.code);
                            commit("setDangQuetMa");
                        }
                    }
                } catch (error) {
                    commit("setDataQuetMa", { Error: error });
                    commit("setDuocQuetMa", true);
                    // self.thongBaoLoi(error);
                }
            });
        },
        kiemTraQrQuetMa({ state, commit }) {
            if (!state.dataQuetMa) return {
                check: false,
            };
            try {
                let data = this.$Helper.DecodeQrData_URL(state.dataQuetMa);
                if (data.MaChuyenDi) {
                    return {
                        check: true,
                        codeCheck: this.$i18n.global.t("StringCheck.QrVe")
                    };
                } else {
                    return {
                        check: false,
                        codeCheck: this.$i18n.global.t("StringCheck.QrVe"),
                        msg: "Mã QR không hợp lệ!"
                    };
                }
            } catch (error) {
                console.log("🚀 ~ file: QrCode.js ~ line 82 ~ kiemTraQrQuetMa ~ error", error)
                let sdt = state.dataQuetMa.split("<<");
                let checkQR = false;
                let checkPcCovid = state.dataQuetMa.indexOf("<<") != -1;
                if (sdt && Array.isArray(sdt) && sdt.length > 0) {
                    if (!parseInt(sdt[1])) {
                        checkQR = false;
                    } else {
                        checkQR = true;
                    }
                } else {
                    checkPcCovid = false;
                }
                return {
                    check: checkPcCovid && checkQR,
                    codeCheck: this.$i18n.global.t("StringCheck.QrSDT"),
                    msg: !checkPcCovid
                        ? "Mã QR không hợp lệ!"
                        : !checkQR
                        ? "Vui lòng bỏ ẩn QR!"
                        : ""
                };
            }
        }
    }
};